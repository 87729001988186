import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Button, Radio } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";

export default function TechnicalHelp() {
  const navigate = useNavigate();
  const [selectedIssue, setSelectedIssue] = useState("");
  //const [otherIssue, setOtherIssue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const issues = [
    "Je n'ai pas accès à mon rapport de diagnostic alors que j'ai payé ma facture",
    "Je n'ai pas accès à ma facture",
    "Je n'arrive pas à signer le consentement pour le DPE",
    "Autre problème technique"
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (!selectedIssue) {
      setError("Veuillez sélectionner un problème.");
      setIsSubmitting(false);
      return;
    }

   /* if (selectedIssue === "Autre problème technique" && !otherIssue.trim()) {
      setError("Veuillez décrire votre problème technique.");
      setIsSubmitting(false);
      return;
    }*/

    try {
      await axiosInstance.post("/technical-help", {
        issue: selectedIssue
      });
      setSuccess(true);
    } catch (error) {
      console.error("Erreur lors de l'envoi du problème technique:", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Aide technique - Espace client Dimo Diagnostic</title>
        <meta name="description" content="Signalez un problème technique avec votre espace client Dimo Diagnostic." />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-4">
          <div className="mb-6">
            <Typography variant="h3" color="blue-gray" className="mb-4 w-full">
              Aide technique - Espace client
            </Typography>
            {success ? (
              <div className="text-center">
                <Typography variant="h6" color="green" className="mb-4">
                  Votre problème technique a bien été signalé.
                </Typography>
                <Typography color="blue-gray" className="mb-4">
                  Merci pour votre signalement. Nous examinerons votre demande dans les plus brefs délais.
                </Typography>
                <Button
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={() => navigate("/dashboard")}
                >
                  Retour à l'accueil
                </Button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <Typography className="mb-4">
                  Ce formulaire vous permet de signaler un problème technique <span className="font-bold">uniquement lié à votre espace client</span>. Pour toute autre demande, telle que la facturation ou un problème avec votre diagnostic, veuillez consulter la page Contact & Aide.
                </Typography>
                <div className="flex flex-col space-y-2 mb-4 w-full">
                  {issues.map((issue, index) => (
                    <Radio
                      key={index}
                      name="issue"
                      label={issue}
                      value={issue}
                      onChange={() => setSelectedIssue(issue)}
                      checked={selectedIssue === issue}
                    />
                  ))}
                </div>
            {/*
            {selectedIssue === "Autre problème technique" && (
              <Textarea
                label="Décrivez votre problème technique"
                value={otherIssue}
                onChange={(e) => setOtherIssue(e.target.value)}
                className="mb-4"
              />
            )}
            */}
                {error && (
                  <Typography color="red" className="mb-4">
                    {error}
                  </Typography>
                )}
                <Button
                  type="submit"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </Button>
              </form>
            )}
          </div>
        </div>
      </Card>
    </Layout>
  );
}