import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Typography,
  Button,
  Checkbox,
  Textarea,
  Spinner
} from "@material-tailwind/react";

const DECLINE_REASONS = [
  "Disponibilités insuffisantes",
  "Vous n'avez pas réussi à nous joindre",
  "Vous avez trouvé moins cher",
  "Notre offre ne vous semble pas fiable",
  "Une autre entreprise vous a été recommandée",
  "Vous nous faites une blague, finalement vous êtes d'accord",
  "Autre(s)"
];

const DeclineQuote = ({ isPublic }) => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [comments, setComments] = useState('');

  const handleReasonToggle = (reason) => {
    setSelectedReasons(prev => 
      prev.includes(reason)
        ? prev.filter(r => r !== reason)
        : [...prev, reason]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedReasons.length === 0) {
      setError("Veuillez sélectionner au moins une raison");
      return;
    }

    setLoading(true);
    try {
      // Here you would typically make an API call to save the decline reason
      // await axiosInstance.post(...);
      
      // Navigate back to quote page
      navigate(isPublic ? `/public-quote/${token}` : `/quote/${id}`, {
        state: { message: 'Devis refusé' }
      });
    } catch (err) {
      setError("Une erreur est survenue lors du refus du devis");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="max-w-2xl mx-auto my-8 p-6">
      <Typography variant="h3" className="text-center mb-2">
        Aïe
      </Typography>

      <Typography className="text-center mb-6">
        Ne regrettons de ne pas vous avoir suffisamment séduit.
      </Typography>

      <Typography className="mb-4">
        Afin d'améliorer notre proposition, n'hésitez pas à nous partager les motifs de ce choix
      </Typography>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          {DECLINE_REASONS.map((reason) => (
            <div key={reason} className="flex items-start gap-4">
              <Checkbox
                checked={selectedReasons.includes(reason)}
                onChange={() => handleReasonToggle(reason)}
              />
              <Typography className="mt-1">
                {reason}
              </Typography>
            </div>
          ))}
        </div>

        <div className="space-y-2">
          <Typography>Commentaire(s) :</Typography>
          <Textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows={6}
          />
        </div>

        {error && (
          <Typography color="red" className="text-center">
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          variant="outlined"
          color="red"
          className="w-full"
          disabled={loading}
        >
          {loading ? (
            <Spinner className="h-4 w-4 mx-auto" />
          ) : (
            <>Refuser définitivement cette offre 😢</>
          )}
        </Button>
      </form>
    </Card>
  );
};

export default DeclineQuote;