import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Button,
  IconButton,
  Alert,
  Checkbox,
  Spinner,
} from "@material-tailwind/react";
import {
  XMarkIcon,
  PrinterIcon,
  CurrencyEuroIcon,
  UserIcon,
  HomeIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  DocumentDuplicateIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";

const STEPS = {
  DOCUMENT_SELECTION: 0,
  DELIVERY_INFO: 1,
  CONFIRMATION: 2,
};

export default function PrintModal({ 
  isOpen, 
  onClose, 
  documents = [], 
  dealId, 
  printAsked,
  isLoadingDocuments,
  onPrint
}) {
  const [currentStep, setCurrentStep] = useState(STEPS.DOCUMENT_SELECTION);
  const [selectedDocs, setSelectedDocs] = useState({});
  const [recipient, setRecipient] = useState({ 
    name: "", 
    firstName: "", 
    address: "", 
    addressComplement: "", 
    zip: "", 
    city: "" 
  });
  const [billingInfo, setBillingInfo] = useState({
    name: "",
    firstName: "",
    address: "",
    addressComplement: "",
    zip: "",
    city: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [acceptCGVU, setAcceptCGVU] = useState(false);
  const [acceptImmediateExecution, setAcceptImmediateExecution] = useState(false);
  const [acceptWaiverOfWithdrawal, setAcceptWaiverOfWithdrawal] = useState(false);
  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  const getDocumentPrice = (type) => type === 'report' ? 29 : 3;

  const calculateTotal = () => {
    return Object.entries(selectedDocs).reduce((total, [docId, quantity]) => {
      const doc = documents.find(d => d.id === docId);
      return total + (getDocumentPrice(doc.type) * quantity);
    }, 0);
  };

  const handleQuantityChange = (docId, quantity) => {
    setSelectedDocs(prev => ({
      ...prev,
      [docId]: Math.max(0, parseInt(quantity) || 0)
    }));
  };

  const handleDocumentToggle = (docId) => {
    setSelectedDocs(prev => {
      const newDocs = { ...prev };
      if (newDocs[docId]) {
        delete newDocs[docId];
      } else {
        newDocs[docId] = 1;
      }
      return newDocs;
    });
  };

  const handlePreview = (doc) => {
    const url = doc.webViewLink || doc.file;
    window.open(url, '_blank');
  };

  const handlePrint = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await axiosInstance.post('/print', {
        dealId,
        documents: Object.entries(selectedDocs)
          .filter(([_, quantity]) => quantity > 0)
          .map(([docId, quantity]) => {
            const doc = documents.find(d => d.id === docId);
            return {
              documentId: docId,
              quantity,
              name: doc.name,
              type: doc.type,
              unitPrice: getDocumentPrice(doc.type)
            };
          }),
        recipient,
        billingInfo: sameBillingAddress ? recipient : billingInfo,
        acceptCGVU,
        acceptImmediateExecution,
        acceptWaiverOfWithdrawal
      });

      if (response.data.success) {
        setApiResponse(response.data);
      } else {
        setError("La requête a échoué. Veuillez réessayer.");
      }
    } catch (err) {
      setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
      console.error("Error during API call:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetAndClose = () => {
    setCurrentStep(STEPS.DOCUMENT_SELECTION);
    setSelectedDocs({});
    setRecipient({ name: "", firstName: "", address: "", addressComplement: "", zip: "", city: "" });
    setBillingInfo({ name: "", firstName: "", address: "", addressComplement: "", zip: "", city: "" });
    setSameBillingAddress(true);
    setAcceptCGVU(false);
    setAcceptImmediateExecution(false);
    setAcceptWaiverOfWithdrawal(false);
    setApiResponse(null);
    setError(null);
    onClose();
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.DOCUMENT_SELECTION:
        return (
          <div className="space-y-4">
            {printAsked && ( 
              <Alert
                icon={<CheckCircleIcon className="h-6 w-6" />}
                color="green"
                className="mb-4"
              >
                Une commande d'impression est déjà en cours.
              </Alert>
            )}

            <Typography variant="h6" className="mb-4">
              Sélectionnez les documents à imprimer
            </Typography>
            {isLoadingDocuments ? (
              <div className="flex justify-center items-center h-40">
                <Spinner className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
                <Typography variant="small" color="gray" className="ml-2">
                  Chargement des documents, cela peut prendre jusqu'à 30 secondes...
                </Typography>
              </div>
            ) : !documents || documents.length === 0 ? (
              <Alert color="red">
                Aucun document disponible pour impression.
              </Alert>
            ) : (
              documents.map((doc) => (
                <div key={doc.id} className="flex items-center justify-between p-4 border rounded-lg">
                  <div className="flex items-center gap-4 flex-1">
                    <Checkbox
                      checked={!!selectedDocs[doc.id]}
                      onChange={() => handleDocumentToggle(doc.id)}
                    />
                    {doc.type === 'report' ? (
                      <DocumentDuplicateIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                    ) : (
                      <DocumentTextIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                    )}
                    <div className="flex-1">
                      <Typography variant="small" className="font-medium text-black break-all">
                        {doc.name}
                      </Typography>
                      <Typography variant="small">
                        {getDocumentPrice(doc.type)}€ par copie
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {selectedDocs[doc.id] !== undefined && (
                      <div className="flex flex-col items-start mr-2">
                        <Input
                          type="number"
                          value={selectedDocs[doc.id]}
                          onChange={(e) => handleQuantityChange(doc.id, e.target.value)}
                          className="!w-20"
                          containerProps={{ className: "min-w-[80px]" }}
                          label="Quantité"
                          placeholder="1"
                        />
                      </div>
                    )}
                    <Button
                      variant="text"
                      color="blue-gray"
                      className="p-2"
                      onClick={() => handlePreview(doc)}
                    >
                      <EyeIcon className="h-5 w-5" />
                    </Button>
                  </div>
                </div>
              ))
            )}
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
              <div className="flex justify-between items-center">
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">{calculateTotal()}€ TTC</Typography>
              </div>
            </div>
          </div>
        );

        case STEPS.DELIVERY_INFO:
          return (
            <div className="space-y-6">
              <Typography variant="h6" className="mb-4 flex items-center">
                <UserIcon className="h-5 w-5 mr-2" />
                Informations du destinataire
              </Typography>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Input label="Prénom" value={recipient.firstName} onChange={(e) => setRecipient({...recipient, firstName: e.target.value})} />
                <Input label="Nom" value={recipient.name} onChange={(e) => setRecipient({...recipient, name: e.target.value})} />
              </div>
              <div className="space-y-4">
                <Input 
                  label="Adresse" 
                  value={recipient.address} 
                  onChange={(e) => setRecipient({...recipient, address: e.target.value})}
                  icon={<HomeIcon className="h-5 w-5" />}
                />
                <Input 
                  label="Complément d'adresse" 
                  value={recipient.addressComplement} 
                  onChange={(e) => setRecipient({...recipient, addressComplement: e.target.value})} 
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <Input 
                    label="Code postal" 
                    value={recipient.zip} 
                    onChange={(e) => setRecipient({...recipient, zip: e.target.value})} 
                  />
                  <Input 
                    label="Ville" 
                    value={recipient.city} 
                    onChange={(e) => setRecipient({...recipient, city: e.target.value})} 
                  />
                </div>
              </div>
              {!sameBillingAddress && (
                <div className="space-y-6 mt-4">
                  <Typography variant="h6" className="mb-4 flex items-center">
                    <UserIcon className="h-5 w-5 mr-2" />
                    Informations de facturation
                  </Typography>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <Input
                      label="Prénom"
                      value={billingInfo.firstName}
                      onChange={(e) => setBillingInfo({ ...billingInfo, firstName: e.target.value })}
                      className="w-full"
                    />
                    <Input
                      label="Nom"
                      value={billingInfo.name}
                      onChange={(e) => setBillingInfo({ ...billingInfo, name: e.target.value })}
                      className="w-full"
                    />
                  </div>
                  <div className="space-y-4">
                    <Input
                      label="Adresse"
                      value={billingInfo.address}
                      onChange={(e) => setBillingInfo({ ...billingInfo, address: e.target.value })}
                      icon={<HomeIcon className="h-5 w-5" />}
                      className="w-full"
                    />
                    <Input
                      label="Complément d'adresse"
                      value={billingInfo.addressComplement}
                      onChange={(e) => setBillingInfo({ ...billingInfo, addressComplement: e.target.value })}
                      className="w-full"
                    />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <Input
                        label="Code postal"
                        value={billingInfo.zip}
                        onChange={(e) => setBillingInfo({ ...billingInfo, zip: e.target.value })}
                        className="w-full"
                      />
                      <Input
                        label="Ville"
                        value={billingInfo.city}
                        onChange={(e) => setBillingInfo({ ...billingInfo, city: e.target.value })}
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        
      case STEPS.CONFIRMATION:
        return (
          <div className="space-y-6">
            <Typography variant="h6" className="mb-4">
              Récapitulatif de votre commande
            </Typography>
            <div className="bg-gray-100 p-6 rounded-lg space-y-4">
              {Object.entries(selectedDocs)
                .filter(([_, quantity]) => quantity > 0)
                .map(([docId, quantity]) => {
                  const doc = documents.find(d => d.id === docId);
                  const unitPrice = getDocumentPrice(doc.type);
                  const totalPrice = unitPrice * quantity;
                  return (
                    <div key={docId} className="flex justify-between items-center">
                      <div>
                        <Typography variant="small" className="font-medium text-black">{doc.name}</Typography>
                        <Typography variant="small" >
                          {unitPrice}€ x {quantity}
                        </Typography>
                      </div>
                      <Typography variant="small" className="font-medium text-black">{totalPrice}€</Typography>
                    </div>
                  );
              })}
              <div className="pt-4 border-t border-gray-300">
                <div className="flex justify-between items-center">
                  <Typography variant="small" className="font-medium text-black">Livraison 4 jours ouvrés</Typography>
                  <Typography variant="small" className="text-green-500 font-semibold">Offerte</Typography>
                </div>
              </div>
              <div className="pt-4 border-t border-gray-300">
                <div className="flex justify-between items-center">
                  <Typography variant="h6" >Total</Typography>
                  <Typography variant="h6" >{calculateTotal()}€ TTC</Typography>
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex items-center">
                <Checkbox
                  checked={acceptCGVU}
                  onChange={() => setAcceptCGVU(!acceptCGVU)}
                  className="mt-1"
                />
                <Typography variant="small" className="ml-2">
                  J'accepte les{" "}
                  <a
                    href="https://www.dimo-diagnostic.net/cgvu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700 underline"
                  >
                    Conditions Générales de Vente et d'Utilisation
                  </a>
                  <span className="text-red-500">*</span>
                </Typography>
              </div>
              <div className="flex items-center">
                <Checkbox
                  checked={acceptImmediateExecution}
                  onChange={() => setAcceptImmediateExecution(!acceptImmediateExecution)}
                  className="mt-1"
                />
                <Typography variant="small" className="ml-2">
                J'accepte l'exécution immédiate de ma commande.
                  <span className="text-red-500">*</span>
                </Typography>
              </div>
              <div className="flex items-center">
                <Checkbox
                  checked={acceptWaiverOfWithdrawal}
                  onChange={() => setAcceptWaiverOfWithdrawal(!acceptWaiverOfWithdrawal)}
                  className="mt-1"
                />
                <Typography variant="small" className="ml-2">
                  Je reconnais renoncer à mon droit de rétractation pour toute impression (« livraison de biens nettement personnalisés »).
                  <span className="text-red-500">*</span>
                </Typography>
              </div>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg">
              <Typography variant="h6" className="mb-2">Adresse de livraison {sameBillingAddress ? "et de facturation" : ""}</Typography>
              <Typography variant="small" >
                {recipient.firstName} {recipient.name}
                <br />
                {recipient.address}
                {recipient.addressComplement && (
                  <>
                    <br />
                    {recipient.addressComplement}
                  </>
                )}
                <br />
                {recipient.zip} {recipient.city}
              </Typography>
            </div>
            {!sameBillingAddress && (
              <div className="bg-gray-100 p-6 rounded-lg">
                <Typography variant="h6"   className="mb-2">Adresse de facturation</Typography>
                <Typography variant="small" >
                  {billingInfo.firstName} {billingInfo.name}
                  <br />
                  {billingInfo.address}
                  {billingInfo.addressComplement && (
                    <>
                      <br />
                      {billingInfo.addressComplement}
                    </>
                  )}
                  <br />
                  {billingInfo.zip} {billingInfo.city}
                </Typography>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} handler={resetAndClose} size="md">
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h5" className="flex items-center">
          <PrinterIcon className="h-6 w-6 mr-2" />
          Impression et envoi du dossier
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={resetAndClose}>
          <XMarkIcon className="h-5 w-5" />
        </IconButton>
      </DialogHeader>
      <DialogBody divider className="overflow-y-auto max-h-[60vh] text-blue-gray-900">
        {apiResponse ? (
          <Alert
            icon={<CheckCircleIcon className="h-6 w-6" />}
            color="green"
            className="mb-4"
          >
            Votre demande d'impression a été enregistrée, veuillez procéder au paiement pour finaliser votre commande.
          </Alert>
        ) : error ? (
          <Alert color="red" className="mb-4">
            {error}
          </Alert>
        ) : (
          renderStepContent()
        )}
      </DialogBody>
      <DialogFooter className="space-x-2">
        {apiResponse ? (
          <Button
            variant="gradient"
            color="green"
            onClick={() => window.location.href = apiResponse.paymentLink}
            className="flex items-center"
          >
            <CurrencyEuroIcon className="h-4 w-4 mr-2" />
            Procéder au paiement
          </Button>
        ) : (
          <>
            {currentStep > STEPS.DOCUMENT_SELECTION && (
              <Button 
                variant="text" 
                color="blue-gray" 
                onClick={() => setCurrentStep(prev => prev - 1)}
                className="flex items-center"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />
                Retour
              </Button>
            )}
            <Button 
              variant="gradient" 
              color="green"
              onClick={() => {
                if (currentStep === STEPS.CONFIRMATION) {
                  handlePrint();
                } else {
                  setCurrentStep(prev => prev + 1);
                }
              }}
              className="flex items-center"
              disabled={
                (currentStep === STEPS.DOCUMENT_SELECTION && Object.keys(selectedDocs).filter(key => selectedDocs[key] > 0).length === 0) ||
                (currentStep === STEPS.DELIVERY_INFO && (!recipient.name || !recipient.firstName || !recipient.address || !recipient.zip || !recipient.city)) ||
                (currentStep === STEPS.CONFIRMATION && (!acceptCGVU || !acceptImmediateExecution || !acceptWaiverOfWithdrawal)) ||
                isLoading
              }
            >
              {isLoading ? (
                "Chargement..."
              ) : currentStep === STEPS.CONFIRMATION ? (
                <>
                  <CurrencyEuroIcon className="h-4 w-4 mr-2" />
                  Valider et passer au paiement
                </>
              ) : (
                <>
                  Suivant
                  <ArrowRightIcon className="h-4 w-4 ml-2" />
                </>
              )}
            </Button>
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
}
