import React from "react";

export default function Logo() {
    return (
        <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" width="966.227" height="464.696" viewBox="0 0 966.227 464.696" className="w-auto h-12 mx-auto">
            <g id="Calque_2-2" data-name="Calque 2-2" transform="translate(0)">
                <g id="Calque_1-2" data-name="Calque 1-2">
                    <g id="Groupe_6" data-name="Groupe 6">
                        <g id="Groupe_5" data-name="Groupe 5">
                            <path id="Tracé_27" data-name="Tracé 27" d="M445.949,66.146c-28.846-.079-49.359,9.892-62.5,25.637a67.233,67.233,0,0,0-55.669-25.637,72.9,72.9,0,0,0-52.4,18.44V74.523H200.4V299.431h74.981V170.781a42.1,42.1,0,0,1,6.761-26.988,25.329,25.329,0,0,1,20.97-9.1,21.934,21.934,0,0,1,18.668,8.012,35.129,35.129,0,0,1,5.511,22.871v133.9h74.981V170.831a41.867,41.867,0,0,1,8.069-26.988,26.709,26.709,0,0,1,21.634-9.1,26.024,26.024,0,0,1,20.548,8,35.021,35.021,0,0,1,7.4,22.871V299.431h74.96V160a99.245,99.245,0,0,0-24.8-67.956,85.765,85.765,0,0,0-64.174-25.909" transform="translate(180.693 59.316)" fill="#052c2b" />
                            <path id="Tracé_28" data-name="Tracé 28" d="M148.76,72.829V295.378h69.206V70.47H148.76Z" transform="translate(134.259 63.368)" fill="#052c2b" />
                            <path id="Tracé_29" data-name="Tracé 29" d="M173.384,27.257v86.26a118.458,118.458,0,1,0,0,210.013v12.865h74.96V24.92h-74.96ZM119.559,263.672A45.163,45.163,0,1,1,164.7,218.516a45.163,45.163,0,0,1-45.142,45.156" transform="translate(0.069 22.409)" fill="#052c2b" />
                            <path id="Tracé_30" data-name="Tracé 30" d="M188.382,0a45.154,45.154,0,1,0,45.149,45.163A45.535,45.535,0,0,0,188.382,0" transform="translate(129.295)" fill="#052c2b" />
                        </g>
                        <path id="Tracé_31" data-name="Tracé 31" d="M502.068,287.88H501.9a179.691,179.691,0,0,1-102.633-32.706,32.263,32.263,0,1,1,37.358-52.61,116.4,116.4,0,0,0,65.411,20.8h.079a116.081,116.081,0,0,0,64.325-19.9,32.261,32.261,0,1,1,36.788,53.006,179.544,179.544,0,0,1-101.171,31.445h0Z" transform="translate(347.296 176.788)" fill="#052c2b" />
                        <path id="Tracé_32" data-name="Tracé 32" d="M418.761,102.03A117.555,117.555,0,1,0,585.147,268.138,117.556,117.556,0,1,0,418.76,102.028M546.517,185.08a44.577,44.577,0,1,1-44.577-44.534,44.577,44.577,0,0,1,44.577,44.534" transform="translate(346.716 60.548)" fill="#052c2b" />
                    </g>
                </g>
            </g>
        </svg>
    );
}   