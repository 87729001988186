import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Spinner, Button, Typography } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import ModifyAppointmentModal from "../components/modals/ModifyAppointmentModal";
import CancelAppointmentModal from "../components/modals/CancelAppointmentModal";
import { MapPinIcon, CalendarIcon, IdentificationIcon } from "@heroicons/react/24/outline";

export default function Appointment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, error } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  useEffect(() => {
    if (!loading && properties.length > 0) {
      if (id) {
        const property = properties.find(p => p.id.toString() === id);
        if (property && property.actionsNeeded.appointment) {
          setSelectedProperty(property);
        } else {
          navigate("/appointment", { replace: true });
        }
      } else {
        const propertyNeedingAppointment = properties.find(p => p.actionsNeeded.appointment);
        if (propertyNeedingAppointment) {
          navigate(`/appointment/${propertyNeedingAppointment.id}`, { replace: true });
        } else {
          setSelectedProperty(null);
        }
      }
    }
  }, [id, properties, loading, navigate]);

  const propertiesNeedingAppointment = properties.filter(p => p.actionsNeeded.appointment);

  const handlePropertyChange = (newId) => {
    const property = properties.find(p => p.id.toString() === newId.toString());
    setSelectedProperty(property);
    navigate(`/appointment/${newId}`, { replace: true });
    setIframeLoading(true);
  };

  return (
    <Layout>
      <Helmet>
        <title>Préparer votre rendez-vous - Dimo Diagnostic</title>
        <meta name="description" content="Planifiez votre rendez-vous pour le diagnostic immobilier avec Dimo Diagnostic." />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/appointment${id ? `/${id}` : ''}`} />
      </Helmet>
      
      {loading ? (
        <div className="flex justify-center items-center h-[calc(100vh-200px)]">
          <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
        </div>
      ) : error ? (
        <Card className="mb-6 p-6 shadow-md">
          <p className="text-red-500">Une erreur s'est produite lors du chargement des données. Veuillez réessayer plus tard.</p>
        </Card>
      ) : propertiesNeedingAppointment.length === 0 ? (
        <Card className="mb-6 p-6 shadow-md">
          <p>Il n'y a actuellement aucun dossier nécessitant la planification d'un rendez-vous.</p>
        </Card>
      ) : (
        <>
          {propertiesNeedingAppointment.length > 1 && (
            <Card className="mb-6 p-6 shadow-md">
              <PropertySelector
                selectedProperty={selectedProperty ? selectedProperty.id : null}
                setSelectedProperty={handlePropertyChange}
                properties={propertiesNeedingAppointment}
                isLoading={loading}
              />
            </Card>
          )}

          {selectedProperty && (
            <>
              <Card className="mb-6 p-6 shadow-md">
                <Typography variant="h5" color="black" className="mb-4">
                  Détails du rendez-vous
                </Typography>
                <div className="space-y-2 mb-4">
                  <div className="flex items-center">
                    <MapPinIcon className="h-5 w-5 mr-2" style={{ color: colors.dimoPrimary }} />
                    <Typography color="black">{selectedProperty.address}</Typography>
                  </div>
                  <div className="flex items-center">
                    <CalendarIcon className="h-5 w-5 mr-2" style={{ color: colors.dimoPrimary }} />
                    <Typography color="black">{selectedProperty.appointmentText}</Typography>
                  </div>
                  <div className="flex items-center">
                    <IdentificationIcon className="h-5 w-5 mr-2" style={{ color: colors.dimoPrimary }} />
                    <Typography color="black">Dossier: {selectedProperty.id}</Typography>
                  </div>
                </div>
                <div className="flex gap-4">
                  <Button
                    style={{ backgroundColor: "white", border: `1px solid ${colors.dimoPrimary}`, color: colors.dimoPrimary }}
                    onClick={() => setIsCancelModalOpen(true)}
                  >
                    Annuler
                  </Button>
                  <Button
                    style={{ backgroundColor: colors.dimoPrimary }}
                    onClick={() => setIsModifyModalOpen(true)}
                  >
                    Modifier
                  </Button>
                </div>
              </Card>

              <Card className="mb-6 p-6 shadow-md">
                <div className="relative w-full h-[calc(100vh-100px)]">
                  {iframeLoading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-75">
                      <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
                    </div>
                  )}
                  <iframe
                    src={selectedProperty.appointment}
                    title="Planifier un rendez-vous"
                    className="w-full h-full border-0"
                    onLoad={() => setIframeLoading(false)}
                  />
                </div>
              </Card>
            </>
          )}
        </>
      )}

      <ModifyAppointmentModal
        isOpen={isModifyModalOpen}
        onClose={() => setIsModifyModalOpen(false)}
        supportPhone="+33 4 82 29 76 45"
        supportEmail="planification@dimo-diagnostic.net"
      />

      <CancelAppointmentModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        propertyId={selectedProperty ? selectedProperty.id : null}
      />
    </Layout>
  );
}