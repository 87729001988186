import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Radio,
} from "@material-tailwind/react";
import axiosInstance from "../../utils/axiosInstance";

const cancelReasons = [
  { id: "unavailable", label: "Je ne suis plus disponible à cette date" },
  { id: "reschedule", label: "Je souhaite reporter le rendez-vous" },
  { id: "no_need", label: "Je n'ai plus besoin de ce diagnostic" },
  { id: "other", label: "Autre raison" }
];

export default function CancelAppointmentModal({ isOpen, onClose, propertyId }) {
  const [selectedReason, setSelectedReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleCancel = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
      const response = await axiosInstance.post('/cancel-appointment', {
        propertyId,
        reasonId: selectedReason
      });

      if (response.data.success) {
        setMessage("Votre diagnostic a été annulé, vous allez être redirigé vers votre tableau de bord.");
        setTimeout(() => {
          onClose();
          navigate('/dashboard');
        }, 5000);
      } else {
        throw new Error("Cancellation failed");
      }
    } catch (error) {
      console.error('Error cancelling appointment:', error);
      setMessage("Une erreur s'est produite lors de l'annulation. Veuillez réessayer.");
      setTimeout(() => {
        setMessage("");
        setIsSubmitting(false); // Reset submission state on error
      }, 5000);
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} handler={handleClose} size="sm">
      <DialogHeader>Annuler le diagnostic</DialogHeader>
      <DialogBody divider className="grid gap-4">
        {message ? (
          <Typography color={message.includes("redirigé") ? "gray" : "red"} className="font-medium">
            {message}
          </Typography>
        ) : (
          <>
            <Typography color="gray" className="font-normal">
              Veuillez sélectionner la raison de l'annulation :
            </Typography>
            {cancelReasons.map((reason) => (
              <Radio
                key={reason.id}
                name="cancelReason"
                label={reason.label}
                value={reason.id}
                onChange={(e) => setSelectedReason(e.target.value)}
                checked={selectedReason === reason.id}
                color="red"
                disabled={isSubmitting}
              />
            ))}
          </>
        )}
      </DialogBody>
      <DialogFooter className="space-x-2">
        <Button 
          variant="outlined" 
          color="gray" 
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Retour
        </Button>
        <Button 
          color="red" 
          onClick={handleCancel} 
          disabled={!selectedReason || isSubmitting}
        >
            J'annule mon diagnostic
        </Button>
      </DialogFooter>
    </Dialog>
  );
}