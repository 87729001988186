import React from "react";
import { Card, Typography, Button } from "@material-tailwind/react";
import { 
  DocumentTextIcon, 
  ArrowDownTrayIcon, 
  PaperAirplaneIcon, 
  ShareIcon,
  IdentificationIcon,
  MapPinIcon,
  HomeIcon,
  CalendarIcon,
  ArrowTopRightOnSquareIcon,
  ClockIcon,
  UserIcon,
  ExclamationTriangleIcon,
  ClockIcon as HourglassIcon
} from "@heroicons/react/24/outline";
import QuickActions from "./QuickActions";
import { colors } from "../utils/colors";

export default function DiagnosticsContent({ propertyInfo, onPrintClick, onPayClick, onSendClick, onShareClick, onHelpClick, onDownloadClick }) {
  const renderDiagnosticReport = () => {
    if (!propertyInfo.appointmentIsPast && !propertyInfo.isDownloadable) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Rendez-vous confirmé
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
          Votre rendez-vous est confirmé. Préparez dès maintenant votre rendez-vous.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`https://client.dimo-diagnostic.net/appointment/${propertyInfo.id}`, '_blank')}
          >
            <CalendarIcon className="h-4 w-4" /> Préparer mon RDV
          </Button>
        </div>
      );
    }
    else if (propertyInfo.dpeStatus) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Accord pour le DPE requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
          Vous devez remplir le formulaire d'accord DPE avant de pouvoir accéder à votre rapport de diagnostic.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`https://client.dimo-diagnostic.net/dpe/${propertyInfo.id}`, '')}
          >
            Remplir le formulaire DPE
          </Button>
        </div>
      );
    }  else if (!propertyInfo.isPayable) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <HourglassIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Facture en attente
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Votre dossier est en attente de traitement par notre équipe de facturation. Vous recevrez un e-mail dès que votre facture sera disponible. Une fois la facture payée, vous pourrez accéder à votre dossier.
          </Typography>
        </div>
      );
    } else if (!propertyInfo.isPaided) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Paiement requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Le paiement est nécessaire pour accéder à votre rapport de diagnostics. Si vous avez déjà effectué le paiement, merci de patienter pendant son traitement. Le délai peut varier selon le mode de paiement.
          </Typography>
          {propertyInfo.paymentLink && (
            <Button 
              style={{ backgroundColor: colors.dimoPrimary }} 
              className="mt-4"
              onClick={() => window.open(propertyInfo.paymentLink, '_blank')}
            >
              Procéder au paiement
            </Button>
          )}
        </div>
      );
    } else if (!propertyInfo.isDownloadable) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <HourglassIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Rapport en préparation
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Le rapport de diagnostics n'est pas encore prêt pour le téléchargement. Nos équipes s’efforcent de le préparer dans les meilleurs délais.
          </Typography>
        </div>
      );
    } else {
      return (
        <>
          <div className="flex items-center space-x-2 mb-4">
            <DocumentTextIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Dossier(s) de Diagnostic(s) Technique(s) (DDT)
            </Typography>
          </div>
          <Typography variant="small" className="mb-4 text-black">
            Votre dossier de diagnostics techniques immobiliers disponible en téléchargement
          </Typography>
          <div className="flex flex-wrap gap-4">
            <Button style={{ backgroundColor: colors.dimoPrimary }} className="flex items-center gap-2" onClick={onDownloadClick}>
              <ArrowDownTrayIcon className="h-4 w-4" /> Télécharger
            </Button>
            <Button 
              variant="outlined" 
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
              className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100" 
              onClick={onSendClick}
            > 
              <PaperAirplaneIcon className="h-4 w-4" /> Envoyer
            </Button>

            { propertyInfo.actions.share && (
            <Button 
              variant="outlined" 
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
              className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100" 
              onClick={onShareClick}
            >
              <ShareIcon className="h-4 w-4" /> Partager l'accès
            </Button>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="space-y-8 mb-6">
      <Card className="p-6 shadow-md">
        <Typography variant="h3" className="mb-4 text-black">
          Informations du diagnostic {propertyInfo.id}
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div className="flex items-center space-x-2">
            <IdentificationIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Dossier: {propertyInfo.id}
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <UserIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Nom: {propertyInfo.name}
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <MapPinIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Adresse: {propertyInfo.address}
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <HomeIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Type de propriété: {propertyInfo.typeProperty}
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Année de construction: {propertyInfo.buildingYear}
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <ArrowTopRightOnSquareIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Superficie: {propertyInfo.square} m²
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <ClockIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Date du rendez-vous: {propertyInfo.appointmentText}
            </Typography>
          </div>
        </div>
        <Typography variant="h4" className="mb-2 text-black">
          Rapport de diagnostics
        </Typography>

        {renderDiagnosticReport()}
      </Card>
      
      <QuickActions 
        onPrintClick={onPrintClick} 
        onPayClick={onPayClick} 
        onSendClick={onSendClick} 
        onShareClick={onShareClick} 
        onHelpClick={onHelpClick} 
        propertyInfo={propertyInfo} 
        onDownloadClick={onDownloadClick} 
      />
    </div>
  );
}