import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Card, 
  Input, 
  Button, 
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon, CheckCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Logo from '../utils/logo';
import { colors } from '../utils/colors';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

export default function Login() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const getCookie = (name) => {
    return Cookies.get(name);
  };

  const hasCheckedToken = useRef(false);

  useEffect(() => {
    const checkAuthToken = async () => {
      const authToken = getCookie('authToken');

      if (authToken && !hasCheckedToken.current) {
        hasCheckedToken.current = true;
        try {
          const response = await fetch('/api/check_token');
          const data = await response.json();

          if (data.success) {
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Erreur lors de la vérification du token:', error);
          setErrorMessage('Votre session a expiré. Veuillez vous reconnecter.');
          setIsErrorModalOpen(true);
        }
      }
    };

    checkAuthToken();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsSuccessModalOpen(true);
      } else {
        setErrorMessage(data.error || 'Une erreur est survenue. Veuillez réessayer.');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Erreur lors de la demande du lien magique:', error);
      setErrorMessage('Erreur de connexion. Veuillez réessayer.');
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendLink = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      //check if the response.redirect exists, follow the redirect
      if (data.redirect) {
        window.location.href = data.redirect;
      }
        

      if (response.ok) {
        setIsSuccessModalOpen(true);
      } else {
        setErrorMessage(data.error || 'Une erreur est survenue lors du renvoi du lien.');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Erreur lors du renvoi du lien de connexion:', error);
      setErrorMessage('Erreur de connexion. Veuillez réessayer.');
      setIsErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorParam = params.get('error');
    if (errorParam === 'invalid_token') {
      setErrorMessage('Le lien de connexion est invalide ou a expiré. Veuillez vous reconnecter.');
      setIsErrorModalOpen(true);
    }
    //account pro ou inexistant
    if (errorParam === 'account_not_found') {
      setErrorMessage('Aucun compte n\'est associé à cet e-mail. Veuillez vérifier l\'adresse e-mail saisie. Pour le moment, l\'accès à l\'espace client est réservé aux particuliers.');
      setIsErrorModalOpen(true);
    }
  }, [location]);

  return (
    <div className="bg-gray-50 min-h-screen flex items-center justify-center p-4">
      <Helmet>
        <title>Connexion - Espace client Dimo Diagnostic</title>
        <meta name="description" content="Connectez-vous à votre espace personnel Dimo pour gérer vos rendez-vous et demandes." />
      </Helmet>

      <Card className="w-full max-w-screen-xl bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="flex flex-col md:flex-row">
          {/* Left side - Hidden on mobile */}
          <div className="hidden md:block flex-1 p-8 md:p-12 border-r border-blue-gray-100">
            <div className="mb-8">
              <Logo />
            </div>
            <Typography variant="h2" color="blue-gray" className="mb-4">
              Bienvenue dans votre espace personnel Dimo
            </Typography>
            <Typography variant="paragraph" color="gray" className="mb-6">
              Votre espace pour gérer vos rendez-vous et demandes auprès de nos services.
            </Typography>
          </div>

          {/* Right side - Login form */}
          <div className="flex-1 p-6 md:p-12 flex flex-col justify-center">
            {/* Mobile-only logo and welcome message */}
            <div className="md:hidden mb-6 text-center">
              <Logo />
              <Typography variant="h4" color="blue-gray" className="mt-4 mb-2">
                Bienvenue chez Dimo Diagnostic
              </Typography>
              <Typography variant="small" color="gray">
                Connectez-vous à votre espace personnel
              </Typography>
            </div>

            <Typography variant="h3" color="blue-gray" className="mb-6 md:p-4 text-xl md:text-2xl hidden md:block">
              Se connecter
            </Typography>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <Input
                  type="email"
                  label="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  ripple={true}
                  className="w-full"
                  disabled={isLoading}
                >
                  {isLoading ? 'Connexion en cours...' : 'Connexion'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Card>

      {/* Success Modal */}
      <Dialog open={isSuccessModalOpen} handler={() => setIsSuccessModalOpen(false)} size="xs">
        <DialogHeader className="flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            Lien de connexion envoyé
          </Typography>
          <XMarkIcon 
            className="h-5 w-5 cursor-pointer" 
            onClick={() => setIsSuccessModalOpen(false)}
          />
        </DialogHeader>
        <DialogBody divider className="grid place-items-center gap-4">
          <CheckCircleIcon className="h-16 w-16" style={{ color: colors.dimoPrimary }} />
          <Typography color="blue-gray" variant="h4" className="text-center">
            Vérifiez votre boîte mail
          </Typography>
          <Typography className="text-center font-normal">
            Un lien de connexion a été envoyé à votre adresse e-mail. Veuillez consulter votre boîte de réception pour vous connecter.
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button 
            variant="outlined" 
            color="blue-gray active:blue-gray"
            onClick={() => setIsSuccessModalOpen(false)}
          >
            Fermer
          </Button>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }}
            onClick={handleResendLink}
          >
            Renvoyer le lien
          </Button>
        </DialogFooter>
      </Dialog>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} handler={() => setIsErrorModalOpen(false)} size="xs">
        <DialogHeader className="flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            Erreur de connexion
          </Typography>
          <XMarkIcon 
            className="h-5 w-5 cursor-pointer" 
            onClick={() => setIsErrorModalOpen(false)}
          />
        </DialogHeader>
        <DialogBody divider className="grid place-items-center gap-4">
          <ExclamationTriangleIcon className="h-16 w-16 text-red-500" />
          <Typography color="blue-gray" variant="h4" className="text-center">
            Oups ! Une erreur est survenue
          </Typography>
          <Typography className="text-center font-normal">
            {errorMessage}
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }}
            onClick={() => {
              setIsErrorModalOpen(false);
              setEmail('');
            }}
            fullWidth
          >
            Réessayer
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}