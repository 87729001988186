import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Spinner } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";

export default function Documents() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, error } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    if (!loading && properties.length > 0) {
      if (id) {
        const property = properties.find(p => p.id.toString() === id);
        if (property && property.actionsNeeded.customerDocument) {
          setSelectedProperty(property);
        } else {
          navigate("/documents", { replace: true });
        }
      } else {
        const propertyWithDocumentNeed = properties.find(p => p.actionsNeeded.customerDocument);
        if (propertyWithDocumentNeed) {
          navigate(`/documents/${propertyWithDocumentNeed.id}`, { replace: true });
        } else {
          setSelectedProperty(null);
        }
      }
    }
  }, [id, properties, loading, navigate]);

  const propertiesNeedingDocuments = properties.filter(p => p.actionsNeeded.customerDocument);

  const handlePropertyChange = (newId) => {
    const property = properties.find(p => p.id.toString() === newId.toString());
    setSelectedProperty(property);
    navigate(`/documents/${newId}`, { replace: true });
    setIframeLoading(true);
  };

  return (
    <Layout>
      <Helmet>
        <title>Documents à fournir - Dimo Diagnostic</title>
        <meta name="description" content="Consultez et fournissez les documents nécessaires pour vos diagnostics immobiliers." />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/documents${id ? `/${id}` : ''}`} />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-6 space-y-6">
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            </div>
          ) : error ? (
            <p className="text-red-500">Une erreur s'est produite lors du chargement des données. Veuillez réessayer plus tard.</p>
          ) : propertiesNeedingDocuments.length === 0 ? (
            <p>Il n'y a actuellement aucun dossier nécessitant l'ajout de documents.</p>
          ) : (
            <>
              {propertiesNeedingDocuments.length > 1 && (
                <PropertySelector
                  selectedProperty={selectedProperty ? selectedProperty.id : null}
                  setSelectedProperty={handlePropertyChange}
                  properties={propertiesNeedingDocuments}
                  isLoading={loading}
                />
              )}

              {selectedProperty && (
                <div className="relative w-full h-[calc(100vh-100px)]">
                  {iframeLoading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-75">
                      <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
                    </div>
                  )}
                  <iframe
                    src={selectedProperty.customerDocument}
                    title="Documents à fournir"
                    className="w-full h-full border-0"
                    onLoad={() => setIframeLoading(false)}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </Layout>
  );
}