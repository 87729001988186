import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Card,
  Typography,
  Button,
  Spinner
} from "@material-tailwind/react";
import { useProperties } from '../contexts/PropertiesContext';
import PropertySelector from '../components/PropertySelector';
import { colors } from '../utils/colors';

const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="fr-FR"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5f4375bc26242f0001c97b8b"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="fr"
    >
      <a
        href="https://fr.trustpilot.com/review/dimo-diagnostic.net"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

const Quote = ({ isPublic }) => {
  const { token, id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [embedLoading, setEmbedLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { properties, isLoading: propertiesLoading } = useProperties();
  const effectiveProperties = isPublic ? null : properties;
  const effectivePropertiesLoading = isPublic ? false : propertiesLoading;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!isPublic && !effectivePropertiesLoading && effectiveProperties && effectiveProperties.length > 0) {
      if (!id) {
        const openQuote = effectiveProperties.find(p => p.quoteStatus === 'waiting_response');
        if (openQuote) {
          navigate(`/quote/${openQuote.id}`);
        } else {
          setError("Aucun devis en attente de réponse n'a été trouvé.");
        }
      } else {
        const property = effectiveProperties.find(p => p.id.toString() === id);
        setSelectedProperty(property);
      }
      setLoading(false);
    } else if (isPublic) {
      setLoading(false);
    }
  }, [effectiveProperties, id, isPublic, effectivePropertiesLoading, navigate]);

  useEffect(() => {
    if (selectedProperty) {
      setEmbedLoading(true);
    }
  }, [selectedProperty]);

  if (loading || effectivePropertiesLoading) return (
    <div className="flex justify-center items-center min-h-screen">
      <Spinner className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
    </div>
  );

  if (error) return (
    <div className="text-center p-6">
      <Typography color="red" className="text-xl">
        {error}
      </Typography>
    </div>
  );

  const handlePropertyChange = (value) => {
    setEmbedLoading(true);
    navigate(`/quote/${value}`);
  };

  const pdfUrl = selectedProperty?.quoteFile || `https://api.example.com/public-quote/${token}/pdf`;

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <div className="max-w-full mx-auto p-4 space-y-6">
        {!isPublic && (
          <Card className="mb-6 shadow-md">        
            <PropertySelector
              selectedProperty={selectedProperty?.id}
              setSelectedProperty={handlePropertyChange}
              properties={effectiveProperties}
              isLoading={effectivePropertiesLoading}
            />
          </Card>
        )}

        <Card className="w-full overflow-hidden p-6 bg-white">
        {/*<div className="relative w-full h-auto">
            {embedLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 w-1/2 mx-auto">
                <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
            )}
            {isMobile ? (
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                className="w-full aspect-[7/10] border-0 mx-auto"
                title="Devis"
                onLoad={() => setEmbedLoading(false)}
              />
            ) : (
              <object
                data={pdfUrl}
                type="application/pdf"
                className="w-2/3 aspect-[3/2] border-0 mx-auto"
              >
                <iframe
                  src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                  className="w-full aspect-[3/2] border-0"
                  title="Devis"
                  onLoad={() => setEmbedLoading(false)}
                />
              </object>
            )}

              //message cette page n'est pas disponible



          </div> 
          <div className="sticky bottom-0 bg-white py-4">
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="outlined"
                color="red"
                onClick={() => navigate(isPublic ? `/public-quote/${token}/decline` : `/quote/${selectedProperty.id}/decline`)}
                className="flex-1 max-w-xs w-full"
              >
                Refuser cette offre
              </Button>
              <Button
                style={{ backgroundColor: colors.dimoDark3 }}
                onClick={() => navigate(isPublic ? `/public-quote/${token}/accept` : `/quote/${selectedProperty.id}/accept`)}
                className="flex-1 max-w-xs w-full"
              >
                Accepter ce devis 🤗
              </Button>
            </div>
          </div> */}
          <Typography variant="h4" className="text-center my-8">
            Cette page n'est pas encore disponible, merci de contacter le support en cas de besoin.
          </Typography>

        </Card>

        <Card className="w-full p-8 bg-white">
          <TrustBox />
        </Card>
      </div>
    </>
  );
};

export default Quote;