import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Typography, Button, Spinner, Dialog, DialogHeader, DialogBody, DialogFooter, Input } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";
import SignatureCanvas from 'react-signature-canvas';

export default function DPE() {
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, error: propertiesError } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [consent, setConsent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  });
  const signatureRef = useRef();
  const pdfUrl = "https://client.dimo-diagnostic.net/dpe.pdf";

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!loading && properties.length > 0) {
      if (id) {
        const property = properties.find(p => p.id.toString() === id);
        if (property && property.actionsNeeded.dpe) {
          setSelectedProperty(property);
        } else {
          navigate("/dpe", { replace: true });
        }
      } else {
        const propertyNeedingDPE = properties.find(p => p.actionsNeeded.dpe);
        if (propertyNeedingDPE) {
          navigate(`/dpe/${propertyNeedingDPE.id}`, { replace: true });
        } else {
          setSelectedProperty(null);
        }
      }
    }
  }, [id, properties, loading, navigate]);

  const propertiesNeedingDPE = properties.filter(p => p.actionsNeeded.dpe);

  const handlePropertyChange = (newId) => {
    const property = properties.find(p => p.id.toString() === newId.toString());
    setSelectedProperty(property);
    navigate(`/dpe/${newId}`, { replace: true });
  };

  const handleSubmit = async (choice) => {
    if (signatureRef.current.isEmpty()) {
      setError("Veuillez signer le formulaire avant de soumettre votre choix.");
      return;
    }

    if (choice === "oui") {
      setShowConfirmModal(true);
    } else {
      await submitConsent(choice);
    }
  };

  const handlePersonalInfoChange = (e) => {
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value
    });
  };

  const validatePersonalInfo = () => {
    return Object.values(personalInfo).every(value => value.trim() !== '');
  };

  const submitConsent = async (choice) => {
    if (choice === "oui" && !validatePersonalInfo()) {
      setError("Veuillez remplir tous les champs du formulaire.");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const signatureData = signatureRef.current.toDataURL();
      const response = await axiosInstance.post("/dpe-consent", {
        consent: choice,
        signature: signatureData,
        propertyId: selectedProperty.id,
        personalInfo: choice === "oui" ? personalInfo : null
      });
      if (response.data.success) {
        setSuccess(true);
        setConsent(choice);
      } else {
        throw new Error("Échec de l'enregistrement du consentement.");
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du consentement:", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
      setShowConfirmModal(false);
    }
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };


  const renderPdfViewer = () => (
    <div className="w-full h-full" style={{ aspectRatio: '1 / 1.4142' }}>
      {isMobile ? (
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
          className="w-full border-0"
          title="DPE Consent Document"
        />
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          className="w-full h-full border-0"
        >
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            className="w-full h-full border-0"
            title="DPE Consent Document"
          />
        </object>
      )}
    </div>
  );

  return (
    <Layout>
      <Helmet>
        <title>Formulaire de consentement DPE - Dimo Diagnostic</title>
        <meta name="description" content="Signez le formulaire de consentement pour le Diagnostic de Performance Énergétique (DPE)." />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/dpe${id ? `/${id}` : ''}`} />
      </Helmet>

      {selectedProperty && (
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} gap-6`}>
          {/* Left side - Consent form */}
          <div className={`${isMobile ? 'w-full' : 'w-1/2'}`}>
            <Card className="shadow-md p-6 space-y-6">
              {propertiesNeedingDPE.length > 1 && (
                <PropertySelector
                  selectedProperty={selectedProperty ? selectedProperty.id : null}
                  setSelectedProperty={handlePropertyChange}
                  properties={propertiesNeedingDPE}
                  isLoading={loading}
                />
              )}

              {success ? (
                <div className="flex flex-col items-center justify-center space-y-6">
                  <CheckCircleIcon className="h-16 w-16 text-green-500" />
                  <Typography variant="h4" color="green">
                    Merci pour votre réponse !
                  </Typography>
                  <Typography color="blue-gray" className="text-center">
                    Votre choix a bien été enregistré.
                    Vous pouvez maintenant revenir à votre diagnostic.
                  </Typography>
                  <Button
                    variant="filled"
                    style={{ backgroundColor: colors.dimoPrimary }}
                    onClick={() => navigate(`/diagnostics/${selectedProperty.id}`)}
                  >
                    Retour au diagnostic
                  </Button>
                </div>
              ) : (
                <>
                  <Typography variant="h4" color="blue-gray" className="mb-4">
                    Formulaire de consentement DPE
                  </Typography>
                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    Dans le cadre de la réalisation de votre Diagnostic de Performance
                    Énergétique (DPE), nous devons recueillir votre choix concernant la
                    transmission de vos données personnelles (nom, prénom, e-mail, téléphone)
                    à l'ADEME.
                  </Typography>
                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    <strong>Oui, je consens :</strong> vos données seront transmises à l'ADEME
                    pour d'éventuelles vérifications des compétences des diagnostiqueurs,
                    conformément à la réglementation.
                  </Typography>
                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    <strong>Non, je refuse :</strong> vos données resteront strictement
                    confidentielles et ne seront pas partagées.
                  </Typography>
                  <Typography variant="paragraph" color="blue-gray">
                    Votre décision n'aura aucune incidence sur la réalisation ou la validité
                    de votre DPE. Merci de compléter et signer le formulaire pour valider
                    votre choix.
                  </Typography>

                  <div className="mb-6 flex flex-col items-center">
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Signez ici :
                    </Typography>
                    <div className="border border-gray-300 rounded-md w-1/2">
                      <SignatureCanvas
                        ref={signatureRef}
                        canvasProps={{
                          className: 'signature-canvas w-full',
                          style: { aspectRatio: '2/1' }
                        }}
                      />
                    </div>
                    <Button
                      size="sm"
                      variant="text"
                      color="blue-gray"
                      className="mt-2"
                      onClick={clearSignature}
                    >
                      Effacer la signature
                    </Button>
                  </div>

                  <div className="flex justify-center space-x-4">
                    <Button
                      variant="outlined"
                      color="blue-gray"
                      onClick={() => handleSubmit("oui")}
                      disabled={isSubmitting}
                    >
                      Oui, je consens
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.dimoPrimary }}
                      onClick={() => handleSubmit("non")}
                      disabled={isSubmitting}
                    >
                      Non, je refuse
                    </Button>
                  </div>

                  {error && (
                    <Typography color="red" className="mt-4 text-center">
                      {error}
                    </Typography>
                  )}
                </>
              )}
            </Card>
          </div>

          {/* Right side - PDF viewer */}
          <div className={`${isMobile ? 'w-full' : 'w-1/2'} flex justify-center items-center`}>
            <Card className="shadow-md overflow-hidden" style={{ height: 'calc(100vh - 100px)', width: 'calc((100vh - 100px) / 1.4142)' }}>
              {renderPdfViewer()}
            </Card>
          </div>
        </div>
      )}

      {!selectedProperty && !loading && (
      <Card className="shadow-md p-6 mb-6 space-y-6 w-full">
        <Typography variant="h4" color="blue-gray" className="text-center">
          Formulaire de consentement DPE
        </Typography>
        <Typography variant="paragraph" color="blue-gray" className="text-center">
          Vous n'avez actuellement aucun dossier nécessitant un accord DPE.
        </Typography>
        <Typography variant="paragraph" color="blue-gray" className="text-center">
          Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre support.
        </Typography>
        <div className="flex justify-center">
          <Button
            variant="filled"
            style={{ backgroundColor: colors.dimoPrimary, width: "fit-content" }}
            onClick={() => navigate("/")}
          >
            Retour à l'accueil
          </Button>
        </div>
      </Card>
      )}
      
      {/* Confirmation Modal */}
      <Dialog open={showConfirmModal} handler={() => setShowConfirmModal(false)} size="md">
        <DialogHeader>Confirmation de votre choix</DialogHeader>
        <DialogBody divider>
          <Typography color="blue-gray" className="mb-4">
            En choisissant "Oui", vous acceptez que vos données soient transmises à l'ADEME.
            Dans le cadre d'un contrôle de compétence du diagnostiqueur, il est possible que vous soyez amené à devoir effectuer une nouvelle visite dans votre bien.
          </Typography>
          <Typography color="blue-gray" className="font-semibold mb-4">
            Veuillez confirmer vos informations personnelles :
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="text"
              label="Prénom"
              name="firstName"
              value={personalInfo.firstName}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="text"
              label="Nom"
              name="lastName"
              value={personalInfo.lastName}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="email"
              label="Email"
              name="email"
              value={personalInfo.email}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="tel"
              label="Téléphone"
              name="phone"
              value={personalInfo.phone}
              onChange={handlePersonalInfoChange}
              required
            />
          </div>
          {!validatePersonalInfo() && (
            <Typography color="red" className="mt-4">
              Veuillez remplir tous les champs avant de confirmer.
            </Typography>
          )}
          <Typography color="blue-gray" className="font-semibold mt-4">
            Êtes-vous sûr de vouloir partager vos coordonnées ? Sachez que vous pouvez refuser, cela n'influe pas sur votre diagnostic.
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button
            variant="outlined"
            style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
            onClick={() => submitConsent("oui")}
            disabled={!validatePersonalInfo()}
          >
            Je confirme mon choix
          </Button>
          <Button
            style={{ backgroundColor: colors.dimoPrimary }}
            onClick={() => {
              setShowConfirmModal(false);
              submitConsent("non");
            }}
          >
            Je change d'avis et je refuse
          </Button>
        </DialogFooter>
      </Dialog>
    </Layout>
  );
}