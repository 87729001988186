import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { PropertiesProvider } from "./contexts/PropertiesContext.js";
import Layout from './components/layout/Layout';
import TagManager from '@sooro-io/react-gtm-module'

import Diagnostics from "./pages/Diagnostics";
import Documents from "./pages/Documents";
import Invoices from "./pages/Invoices";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import DPE from "./pages/DPE";
import Appointment from "./pages/Appointment";
import Quote from "./pages/Quote";
import Help from "./pages/Help";
import AcceptQuote from "./pages/AcceptQuote";
import DeclineQuote from "./pages/DeclineQuote";
import SignQuote from "./pages/SignQuote";
import TechnicalHelp from "./pages/TechnicalHelp.js";

// Initialize GTM with custom source
const tagManagerArgs = {
  gtmId: 'GTM-5BGZ6G7',
  source: 'https://server.dimo-diagnostic.net/vtogls875h5u37b.js',
  dataLayer: {
    platform: 'web',
    environment: 'production',
  }
}

TagManager.initialize(tagManagerArgs)


function App() {
  return (
    <ThemeProvider>
      <Helmet>
        <title>Espace client - Dimo Diagnostic</title>
        <meta name="description" content="Bienvenue sur l'application Dimo Diagnostic." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://client.dimo-diagnostic.net" />
        <script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "eacu0e7ep7");
          `}
        </script>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/public-quote/:token" element={<Quote isPublic={true} />} />
          <Route path="/public-quote/:token/accept" element={<AcceptQuote isPublic={true} />} />
          <Route path="/public-quote/:token/decline" element={<DeclineQuote isPublic={true} />} />
          <Route path="/public-quote/:token/sign" element={<SignQuote isPublic={true} />} />
          <Route
            path="/*"
            element={
              <PropertiesProvider>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/diagnostics/:id" element={<Diagnostics />} />
                  <Route path="/diagnostics" element={<Diagnostics />} />
                  <Route path="/dpe/:id" element={<DPE />} />
                  <Route path="/dpe" element={<DPE />} />
                  <Route path="/appointment/:id" element={<Appointment />} />
                  <Route path="/appointment" element={<Appointment />} />
                  <Route path="/documents/:id" element={<Documents />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/quote/:id/accept" element={<Layout><AcceptQuote isPublic={false} /></Layout>} />
                  <Route path="/quote/:id/decline" element={<Layout><DeclineQuote isPublic={false} /></Layout>} />
                  <Route path="/quote/:id/sign" element={<Layout><SignQuote isPublic={false} /></Layout>} />
                  <Route path="/quote/:id" element={<Layout><Quote isPublic={false} /></Layout>} />
                  <Route path="/quote" element={<Layout><Quote isPublic={false} /></Layout>} />
                  <Route path="/technical-help" element={<TechnicalHelp />} />
                  <Route path="*" element={<NotFound />} status={404} />
                </Routes>
              </PropertiesProvider>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;