import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Button, Spinner } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import {
  DocumentTextIcon,
  CreditCardIcon,
  QuestionMarkCircleIcon,
  ArrowUpTrayIcon,
  HomeIcon,
  MapPinIcon,
  IdentificationIcon,
  ClockIcon,
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import { useProperties } from "../contexts/PropertiesContext";

export default function Dashboard() {
  const { properties, loading, error } = useProperties();

  const services = [
    {
      icon: DocumentTextIcon,
      title: "Consulter vos diagnostics",
      description: "Accédez à tous vos diagnostics immobiliers en ligne.",
      path: "/diagnostics",
    },
    {
      icon: ArrowUpTrayIcon,
      title: "Échanger des documents",
      description: "Échangez facilement vos documents avec nos services.",
      path: "/documents",
    },
    {
      icon: CreditCardIcon,
      title: "Accéder à vos factures",
      description: "Consultez et gérez toutes vos factures en quelques clics.",
      path: "/invoices",
    },
    {
      icon: QuestionMarkCircleIcon,
      title: "Page d'aide",
      description: "Trouvez les réponses à vos questions sur la page d'aide.",
      path: "/help",
    },
  ];

  const renderActionItems = (property) => {
    const actions = [];

    if (property.actionsNeeded.quotePending) {
      actions.push({
        title: "Je confirme mon devis",
        description: "Il ne manque qu'un petit effort pour commencer à travailler ensemble.",
        action: `/quote/${property.id}`,
        buttonText: "Je valide mon devis"
      });
    }

    if (property.actionsNeeded.appointment) {
      actions.push({
        title: "Finaliser le rendez-vous",
        description: "Planifiez votre rendez-vous selon vos disponibilités.",
        action: `/appointment/${property.id}`,
        buttonText: "Je planifie mon rendez-vous"
      });
    }

    if (property.actionsNeeded.customerDocument && !property.appointmentIsPast) {
      actions.push({
        title: "Fournir des documents",
        description: "Certains documents sont obligatoires et essentiels pour votre diagnostic.",
        action: `/documents/${property.id}`,
        buttonText: "Je fournis les documents"
      });
    }

    if (property.actionsNeeded.dpe) {
      actions.push({
        title: "Signer le formulaire de consentement",
        description: "C'est une obligation réglementaire pour le DPE.",
        action: `/dpe/${property.id}`,
        buttonText: "Je signe le formulaire"
      });
    }

    return actions;
  };

  return (
    <Layout>
      <Card className="mb-6 shadow-md">
        <div className="p-6 space-y-6">
          <div className="">
            <Typography variant="h3" className="mb-2 text-black">Bonjour 👋</Typography>
            <Typography variant="paragraph" className="mb-2 text-black">Bienvenue sur votre espace client !</Typography>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {services.map((service, index) => (
        <Link to={service.path} key={index} className="block h-full">
          <Card
            className="p-4 flex flex-col items-center text-center bg-gray-50 border border-gray-200 hover:bg-gray-100 hover:border-gray-300 hover:shadow-md transition-all duration-300 h-full"
          >
            <div className="flex flex-col items-center justify-between h-full">
              <div className="flex flex-col items-center">
                <service.icon className="h-10 w-10 mb-2" style={{ color: colors.dimoPrimary }} />
                <Typography variant="h6" color="blue-gray" className="mb-1">{service.title}</Typography>
              </div>
              <Typography variant="small" color="gray" className="font-medium mt-2">{service.description}</Typography>
            </div>
          </Card>
        </Link>
      ))}
    </div>
          <Typography variant="h4" className="mb-4 text-black">
            {properties.length > 2 ? "Vos dossiers" : "Votre dossier"}
          </Typography>

          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            </div>
          ) : error ? (
            <Typography color="red" className="text-center">{error}</Typography>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
              {properties.map((property) => (
                <Card key={property.id} className="p-6 bg-gray-50 border border-gray-200 shadow-md transition-all duration-300">
                  <div className="mb-4">
                    <Typography variant="h5" color="blue-gray" className="flex items-center">
                      <UserIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
                      {property.name}
                    </Typography>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div className="flex items-center space-x-2">
                      <IdentificationIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Dossier: {property.id}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MapPinIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Adresse: {property.address}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <HomeIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Type de propriété: {property.typeProperty}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Année de construction: {property.buildingYear}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ArrowTopRightOnSquareIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Superficie: {property.square} m²
                      </Typography>
                    </div>
                    {property.appointmentText && (
                      <div className="flex items-center space-x-2">
                        <ClockIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Date du rendez-vous: {property.appointmentText}
                        </Typography>
                      </div>
                    )}
                  </div>

                  {renderActionItems(property).length > 0 && (
  <div className="space-y-4 mt-6">
    <Typography variant="h6" color="blue-gray">
      Actions à mener sur votre dossier
    </Typography>
    {renderActionItems(property).map((action, index) => (
      <div key={index} className="bg-white rounded-xl border border-gray-200 p-4">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
          <div>
            <Typography variant="h6" color="blue-gray">
              {action.title}
            </Typography>
            <Typography variant="small" color="gray">
              {action.description}
            </Typography>
          </div>
          <Link
            to={action.action}
            className="w-full sm:w-auto sm:min-w-[200px]"
          >
            <Button
              fullWidth
              className="normal-case mt-4 sm:mt-0"
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              {action.buttonText}
            </Button>
          </Link>
        </div>
      </div>
    ))}
  </div>
)}
                  <div className="mt-4">
                    <Link to={`/diagnostics/${property.id}`}>
                      <Button
                        fullWidth
                        className="normal-case"
                        variant={renderActionItems(property).length > 0 ? "outlined" : "filled"}
                        color={renderActionItems(property).length > 0 ? "gray" : undefined}
                        style={renderActionItems(property).length > 0 ? undefined : { backgroundColor: colors.dimoPrimary }}
                      >
                        Accéder au dossier
                      </Button>
                    </Link>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
}