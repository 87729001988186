import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export default function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeSidebar = () => setIsSidebarOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      {/* Overlay avec animation */}
      <div 
        className={`
          fixed inset-0 bg-black transition-opacity duration-500 ease-in-out lg:hidden
          ${isSidebarOpen ? 'opacity-25 z-40' : 'opacity-0 pointer-events-none'}
        `}
        onClick={closeSidebar}
      ></div>
      <main className="flex-grow p-4 lg:ml-64 transition-all duration-150 ease-in-out">
        {children}
        <Footer />
      </main>
    </div>
  );
}