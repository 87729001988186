import axios from 'axios';

// Crée une instance axios
const axiosInstance = axios.create({
  baseURL: '/api', // Base URL pour toutes les requêtes API
  withCredentials: true, // Permet d'envoyer les cookies JWT
});

// Intercepter les réponses
axiosInstance.interceptors.response.use(
  (response) => {
    // Retourner la réponse si elle est réussie
    return response;
  },
  (error) => {
    // Vérifier si la réponse d'erreur est un 401 ou 403
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      // Rediriger vers la page de connexion avec un message d'erreur
      window.location.href = `/?error=invalid_token`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
