import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Button,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import {
  XMarkIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import axiosInstance from "../../utils/axiosInstance";

export default function ShareAccessModal({ isOpen, onClose, dealId }) {
  const [sharedEmails, setSharedEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  // On utilise useCallback pour mémoriser la fonction
  const fetchSharedEmails = useCallback(async () => {
    if (!dealId) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/shares/${dealId}`);
      setSharedEmails(response.data.shares);
    } catch (error) {
      console.error("Erreur lors de la récupération des partages:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dealId]);

  useEffect(() => {
    if (isOpen && dealId) {
      fetchSharedEmails();
    }
  }, [isOpen, dealId, fetchSharedEmails]); // Ajoutez fetchSharedEmails dans les dépendances

  const handleAddEmail = async (e) => {
    e.preventDefault();
    if (!newEmail) return;

    setIsSharing(true);
    try {
      await axiosInstance.post(`/create-shares/${dealId}`, { email: newEmail });
      setNewEmail("");
      await fetchSharedEmails(); // re-fetch pour mettre à jour la liste
    } catch (error) {
      console.error("Erreur lors de l'ajout du partage:", error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleDeleteShare = async (email) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce partage ?")) {
      try {
        await axiosInstance.post(`/delete-shares/${dealId}`, { shareId: email });
        await fetchSharedEmails(); // re-fetch pour mettre à jour la liste
      } catch (error) {
        console.error("Erreur lors de la suppression du partage:", error);
      }
    }
  };

  return (
    <Dialog open={isOpen} handler={onClose} size="md">
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h5" color="blue-gray" className="flex items-center">
          <LinkIcon className="h-6 w-6 mr-2" />
          Partager l'accès
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </IconButton>
      </DialogHeader>
      <DialogBody divider>
        {isLoading ? (
          <div className="p-4 flex items-center justify-center">
            <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
          </div>
        ) : (
          <div className="space-y-4">
            <Typography color="gray">
              Partagez l'accès à ce dossier avec d'autres personnes :
            </Typography>
            <form onSubmit={handleAddEmail} className="flex items-center space-x-2">
              <Input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Entrez une adresse e-mail"
                className="flex-grow"
              />
              <Button
                type="submit"
                className="flex items-center"
                style={{ backgroundColor: colors.dimoPrimary }}
                disabled={isSharing}
              >
                {isSharing ? (
                  <Spinner className="h-4 w-4 mr-2" />
                ) : (
                  "Partager"
                )}
              </Button>
            </form>
            <div className="mt-4">
              <Typography variant="h6" color="gray">
                Accès partagés :
              </Typography>
              {sharedEmails.length === 0 ? (
                <Typography color="gray">Aucun partage pour le moment.</Typography>
              ) : (
                <ul className="mt-2 space-y-2">
                  {sharedEmails.map((email) => (
                    <li key={email} className="flex items-center justify-between">
                      <Typography className="text-blue-gray-900">{email}</Typography>
                      <IconButton
                        variant="text"
                        color="red"
                        onClick={() => handleDeleteShare(email)}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="outlined"
          style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
          className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100"
          onClick={onClose}
        >
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
