import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";
import { Spinner } from "@material-tailwind/react";
import { colors } from "../utils/colors";

export default function Help() {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Layout>
      <Helmet>
        <title>Aide - Dimo Diagnostic</title>
        <meta name="description" content="Besoin d'aide? Consultez notre page d'aide." />
        <link rel="canonical" href="https://client.dimo-diagnostic.net/help" />
      </Helmet>

      {isLoading && (
        <div className="flex justify-center items-center h-screen">
          <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
        </div>
      )}

      <iframe
        src="https://app.dimo-diagnostic.net/aide"
        title="Aide"
        onLoad={handleIframeLoad}
        style={{
          width: "100%",
          height: "100vh", // Utilise la hauteur de la fenêtre visible
          border: "none",
          display: isLoading ? "none" : "block",
        }}
      />
    </Layout>
  );
}
