import React, { useState, useEffect } from "react";
import { Typography, Card, Spinner, Button } from "@material-tailwind/react";
import {
  DocumentTextIcon,
  ArrowDownTrayIcon,
  CalendarIcon,
  CreditCardIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import Layout from "../components/layout/Layout";
import axiosInstance from '../utils/axiosInstance';
import { colors } from "../utils/colors";
import { Helmet } from 'react-helmet';


export default function Invoices() {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance.get('/invoices')
      .then((response) => {
        setDocuments(response.data.invoices || []);
        setError(null);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des documents:", error);
        setError("Une erreur s'est produite lors de la récupération des factures. Veuillez réessayer plus tard.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const renderDocumentIcon = (type) => {
    return type === 'invoice' ? (
      <DocumentTextIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    ) : (
      <DocumentDuplicateIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    );
  };

  const renderActionButtons = (doc) => {
    const isUnpaidInvoice = doc.type === 'invoice' && doc.status !== 'paid';

    return (
      <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
        {doc.type === 'invoice' && (
          <div className="flex items-center gap-2 mr-2">
            <CheckCircleIcon className={`h-4 w-4 ${doc.status === 'paid' ? 'text-green-500' : 'text-orange-500'}`} />
            <Typography variant="small" className={doc.status === 'paid' ? 'text-green-500' : 'text-orange-500'}>
              {doc.status === 'paid' ? "Payée" : "En attente"}
            </Typography>
          </div>
        )}
        {isUnpaidInvoice && doc.payLink && (
          <a
            href={doc.payLink}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full sm:w-auto"
          >
            <Button 
              className="w-full flex items-center justify-center gap-2"
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              <CreditCardIcon className="h-4 w-4" />
              Payer
            </Button>
          </a>
        )}
        <a
          href={doc.file}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full sm:w-auto"
        >
          <Button 
            variant={isUnpaidInvoice ? "outlined" : "filled"}
            className="w-full flex items-center justify-center gap-2"
            style={isUnpaidInvoice 
              ? { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
              : { backgroundColor: colors.dimoPrimary }
            }
          >
            <ArrowDownTrayIcon className="h-4 w-4" />
            Télécharger
          </Button>
        </a>
      </div>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>Factures - Dimo Diagnostic</title>
        <meta name="description" content="Consultez et téléchargez vos factures et devis." />
        <link rel="canonical" href="https://client.dimo-diagnostic.net/invoices" />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-4">
          <div className="flex items-center gap-3 mb-6">
            <DocumentTextIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            <div>
              <Typography variant="h3" className="text-gray-900">
                Factures
              </Typography>
              <Typography variant="small" className="text-gray-800">
                Consultez et téléchargez vos factures et devis
              </Typography>
            </div>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center py-10">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            </div>
          ) : error ? (
            <div className="bg-red-50 p-4 rounded-lg">
              <Typography color="red" className="text-center">{error}</Typography>
            </div>
          ) : documents && documents.length > 0 ? (
            <div className="space-y-4">
              {documents.map((doc, index) => (
                <Card key={index} className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                    <div className="flex items-start gap-4 flex-1">
                      {renderDocumentIcon(doc.type)}
                      <div className="flex-1">
                        <Typography variant="h6" className="mb-1 text-gray-900">
                          {doc.name}
                        </Typography>
                        <div className="flex flex-wrap gap-x-4 gap-y-2">
                          <div className="flex items-center gap-2">
                            <CalendarIcon className="h-4 w-4 text-gray-800" />
                            <Typography variant="small" className="text-gray-800">
                              Date d'émission: {formatDate(doc.date)}
                            </Typography>
                          </div>
                          {doc.deadline && (
                            <div className="flex items-center gap-2">
                              <ClockIcon className="h-4 w-4 text-gray-800" />
                              <Typography variant="small" className="text-gray-800">
                                Date limite: {formatDate(doc.deadline)}
                              </Typography>
                            </div>
                          )}

                          <div className="flex items-center gap-2">
                            <CreditCardIcon className="h-4 w-4 text-gray-800" />
                            <Typography variant="small" className="text-gray-800">
                              Montant: {doc.amount}€
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderActionButtons(doc)}
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <Card className="p-8 bg-gray-50">
              <div className="text-center">
                <DocumentTextIcon className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <Typography variant="h6" color="gray" className="mb-2">
                  Aucune facture disponible
                </Typography>
                <Typography color="gray">
                  Vos documents apparaîtront ici une fois générés.
                </Typography>
              </div>
            </Card>
          )}
        </div>
      </Card>
    </Layout>
  );
}