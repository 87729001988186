import React from "react";
import { IconButton } from "@material-tailwind/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import Logo from "../../utils/logo";

export default function Header({ toggleSidebar }) {
  return (
    <header className="bg-white shadow-md p-4 flex justify-between items-center lg:hidden">
      <Logo />
      <IconButton
        variant="text"
        style={{ color: colors.dimoPrimary }}
        onClick={toggleSidebar}
      >
        <Bars3Icon className="h-6 w-6" />
      </IconButton>
    </header>
  );
}