import React from "react";
import { Card, Typography } from "@material-tailwind/react";
import {
  CreditCardIcon,
  ArrowDownTrayIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { colors } from "../utils/colors";

export default function QuickActions({ onPrintClick, onPayClick, onSendClick, onShareClick, onDownloadClick, onHelpClick, propertyInfo }) {
  const actions = [
    { id: "pay", icon: CreditCardIcon, label: "Payez", description: "Payez par carte, virement, e-virement, lien de paiement", onClick: onPayClick },
    { id: "download", icon: ArrowDownTrayIcon, label: "Téléchargez", description: "Téléchargez votre dossier, facture, etc.", onClick: onDownloadClick },
    { id: "send", icon: PaperAirplaneIcon, label: "Envoyez", description: "Envoyez votre dossier / facture à qui vous le souhaitez par e-mail", onClick: onSendClick },
    { id: "help", icon: QuestionMarkCircleIcon, label: "Aide", description: "Question(s) ou modification(s) sur votre rapport", onClick: onHelpClick },
    { id: "share", icon: UserGroupIcon, label: "Donner accès", description: "Invitez des utilisateurs à avoir accès à votre dossier", onClick: onShareClick },
    { id: "print", icon: PrinterIcon, label: "Impression", description: "Commandez votre rapport en format papier", onClick: onPrintClick },
  ];

  return (
    <Card className="p-6 shadow-md bg-white mb-6">
      <Typography variant="h3" className="mb-4 text-black">
        Actions rapides sur votre dossier
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {actions.map((action, index) => (
          propertyInfo?.actions?.[action.id] && (
            <Card 
              key={index} 
              className="p-4 flex flex-col items-center text-center bg-gray-50 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 hover:shadow-md transition-all duration-300 cursor-pointer"
              onClick={action.onClick}
            >
              <action.icon className="h-10 w-10 mb-2" style={{ color: colors.dimoPrimary }} />
              <Typography variant="h6" color="blue-gray" className="mb-1">{action.label}</Typography>
              <Typography variant="small" color="gray" className="font-medium">{action.description}</Typography>
            </Card>
          )
        ))}
      </div>
    </Card>
  );
}
