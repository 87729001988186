import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Typography,
  Button,
  Radio,
  Checkbox,
  Spinner
} from "@material-tailwind/react";
import { colors } from '../utils/colors';

const AcceptQuote = ({ isPublic }) => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dpeConsent, setDpeConsent] = useState(null);
  const [diagnosticConsent, setDiagnosticConsent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (dpeConsent === null) {
      setError("Veuillez choisir une option pour le consentement DPE");
      return;
    }

    if (!diagnosticConsent) {
      setError("Veuillez accepter les obligations liées au diagnostic");
      return;
    }

    setLoading(true);
    try {
      // Here you would typically make an API call to save the consent
      // await axiosInstance.post(...);
      
      // Navigate to signature page
      navigate(isPublic ? `/public-quote/${token}/sign` : `/quote/${id}/sign`);
    } catch (err) {
      setError("Une erreur est survenue lors de l'acceptation du devis");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="max-w-2xl mx-auto my-8 p-6">
      <Typography variant="h4" className="text-center mb-6">
        Acceptation de l'offre
      </Typography>

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* DPE Consent Section */}
        <div className="space-y-4">
          <Typography variant="h6" color="blue-gray">
            Obligation liée au DPE (Formulaire de consentement)
          </Typography>

          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <Radio
                name="dpe-consent"
                label="Oui, je consens à ce que mes données personnelles soient traitées par l'ADEME et l'organisme de certification dans le cadre des missions de contrôle des compétences des diagnostiqueurs."
                onChange={() => setDpeConsent(true)}
                checked={dpeConsent === true}
              />
            </div>
            <div className="flex items-center gap-4">
              <Radio
                name="dpe-consent"
                label="Non, je refuse que mes données soient collectées."
                onChange={() => setDpeConsent(false)}
                checked={dpeConsent === false}
              />
            </div>
          </div>

          <div className="bg-blue-gray-50 p-4 rounded-lg text-sm italic">
            💡 Vous pouvez dire non.<br />
            💡 Dire "Oui" implique que vous risquez d'être recontacté par nos organismes de certifications pour qu'ils puissent revenir chez vous afin de vérifier notre travail dans les mois/années à venir. C'est une procédure de surveillance de nos certificats.
          </div>
        </div>

        {/* Diagnostic Obligations Section */}
        <div className="space-y-4">
          <Typography variant="h6" color="blue-gray">
            Obligations liées à tout diagnostic
          </Typography>

          <div className="flex items-start gap-4">
            <Checkbox
              checked={diagnosticConsent}
              onChange={(e) => setDiagnosticConsent(e.target.checked)}
            />
            <Typography className="text-sm">
              En cochant la présente case et en prenant un rendez-vous dans les 14 jours suivant la date de ma commande, je donne mon accord préalable à l'exécution des services avant la fin du délai légal de rétractation et reconnais ainsi renoncer à mon droit de rétractation
            </Typography>
          </div>

          <Typography className="text-sm">
            En signant ce devis ou en l'acceptant via la prise d'un rendez-vous, je déclare consentir aux Conditions Générales de Vente et d'Utilisation, ainsi qu'à l'Ordre de Mission associé.
          </Typography>
        </div>

        {error && (
          <Typography color="red" className="text-center">
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          className="w-full"
          style={{ backgroundColor: colors.dimoDark3 }}
          disabled={loading}
        >
          {loading ? (
            <Spinner className="h-4 w-4 mx-auto" />
          ) : (
            <>Valider et signer cette offre 🤗</>
          )}
        </Button>
      </form>
    </Card>
  );
};

export default AcceptQuote;