import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemPrefix, Button } from "@material-tailwind/react";
import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  HomeIcon,
  CreditCardIcon,
  ArrowUpTrayIcon,
  DocumentCheckIcon,
  CalendarDaysIcon,
  RocketLaunchIcon,
  PlusIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import Logo from "../../utils/logo";
import { useProperties } from "../../contexts/PropertiesContext";

export default function Sidebar({ isOpen, closeSidebar }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const { properties, loading } = useProperties();

  const [notifications, setNotifications] = useState({
    dpe: false,
    appointment: false,
    documents: false
  });

  const [showQuote, setShowQuote] = useState(false);
  const [showWonItems, setShowWonItems] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);

  useEffect(() => {
    if (!loading && properties) {
      const newNotifications = {
        dpe: properties.some(p => p.actionsNeeded.dpe),
        appointment: properties.some(p => p.actionsNeeded.appointment),
        // Show notification only if appointment is not past
        documents: properties.some(p => p.actionsNeeded.customerDocument && !p.appointmentIsPast)
      };
      setNotifications(newNotifications);

      setShowQuote(properties.some(p => p.status === 'open'));
      setShowWonItems(properties.some(p => p.status === 'won'));
      // Show 'Documents' tab if any property needs customer documents
      setShowDocuments(properties.some(p => p.actionsNeeded.customerDocument));
    }
  }, [properties, loading]);

  const handleNavigation = (path) => {
    setActiveTab(path.split('/')[1]);
    navigate(path);
    closeSidebar();
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.get('/logout');
      navigate('/');
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  const handleNewQuote = () => {
    window.open("https://app.dimo-diagnostic.net/mon-devis", "_blank");
  };

  const navItems = [
    { path: "/dashboard", icon: HomeIcon, label: "Accueil", alwaysShow: true },
    { path: "/quote", icon: RocketLaunchIcon, label: "Devis", showIf: showQuote },
    { path: "/appointment", icon: CalendarDaysIcon, label: "Préparer mon RDV", notificationKey: "appointment", showIf: notifications.appointment },
    { path: "/dpe", icon: DocumentCheckIcon, label: "Accord DPE", notificationKey: "dpe", showIf: notifications.dpe },
    { path: "/documents", icon: ArrowUpTrayIcon, label: "Documents", notificationKey: "documents", showIf: showDocuments },
    { path: "/diagnostics", icon: DocumentTextIcon, label: "Diagnostics", showIf: showWonItems },
    { path: "/invoices", icon: CreditCardIcon, label: "Factures", showIf: showWonItems },
    { path: "/help", icon: QuestionMarkCircleIcon, label: "Contact & Aide", alwaysShow: true },
  ];

  const renderNavItem = (item) => (
    <ListItem
      key={item.path}
      className={`cursor-pointer ${activeTab === item.path ? 'bg-gray-100' : ''}`}
      onClick={() => handleNavigation(item.path)}
    >
      <ListItemPrefix>
        <item.icon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
      </ListItemPrefix>
      <span style={{ color: colors.dimoPrimary }}>{item.label}</span>
      {item.notificationKey && notifications[item.notificationKey] && (
        <span className="ml-2 w-2 h-2 bg-red-500 rounded-full opacity-75"></span>
      )}
    </ListItem>
  );

  const renderPlaceholder = () => (
    <div className="flex items-center space-x-4 px-4 py-3">
      <div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse"></div>
      <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
    </div>
  );

  const renderNewQuoteButtonPlaceholder = () => (
    <div className="flex items-center gap-3 mb-4 mx-4 h-10 bg-gray-200 rounded animate-pulse"></div>
  );


  return (
    <aside
      className={`
        fixed top-0 left-0 z-50 w-64 h-screen bg-white shadow-md
        transition-transform duration-300 ease-in-out transform
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0
      `}
    >
      <div className="p-4">
        <Logo />
      </div>
      <List>
        {loading ?
          <>
            {renderNewQuoteButtonPlaceholder()}
            {Array(5).fill(0).map((_, index) => <React.Fragment key={index}>{renderPlaceholder()}</React.Fragment>)}
          </> : (
            <>
              <Button
                style={{ backgroundColor: colors.dimoPrimary }}
                className="flex items-center gap-3 mb-4 mx-4"
                onClick={handleNewQuote}
              >
                <PlusIcon className="h-5 w-5" />
                Nouveau devis
              </Button>
              {navItems.map((item) => (
                (item.alwaysShow || item.showIf) && renderNavItem(item)
              ))}
            </>
          )
        }
      </List>
      <div className="md:absolute bottom-0 left-0 w-full md:p-2 px-2">
        <ListItem className="cursor-pointer" onClick={() => handleNavigation("/technical-help")}>
          <ListItemPrefix>
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>Signaler un problème</span>
        </ListItem>
        <ListItem className="cursor-pointer" onClick={handleLogout}>
          <ListItemPrefix>
            <UserIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>Déconnexion</span>
        </ListItem>
      </div>
    </aside>
  );
}
