import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Typography,
  Button,
  Spinner
} from "@material-tailwind/react";
import SignatureCanvas from 'react-signature-canvas';
import { colors } from '../utils/colors';

const SignQuote = ({ isPublic }) => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const signatureRef = useRef(null);

  // Mock client data - in real app, fetch this from API
  const clientData = {
    offerNumber: "199999",
    lastName: "DUPONT",
    firstName: "Jean Marc",
    phone: "0606066606",
    email: "djm@gmail.com"
  };

  const handleSubmit = async () => {
    if (!signatureRef.current || signatureRef.current.isEmpty()) {
      setError("Veuillez signer avant de valider");
      return;
    }

    setLoading(true);
    try {
      const signatureData = signatureRef.current.toDataURL();
      // Here you would typically make an API call to save the signature
      // await axiosInstance.post(...);
      
      navigate(isPublic ? `/public-quote/${token}` : `/quote/${id}`, {
        state: { message: 'Devis signé avec succès' }
      });
    } catch (err) {
      setError("Une erreur est survenue lors de la signature du devis");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="max-w-2xl mx-auto my-8 p-6">
      <Typography variant="h4" className="text-center mb-8">
        Acceptation de l'offre
      </Typography>

      <div className="space-y-6">
        <div>
          <Typography variant="h5" className="mb-4">
            Client
          </Typography>
          <div className="space-y-2">
            <Typography>
              Offre : {clientData.offerNumber}
            </Typography>
            <Typography className="font-bold">
              {clientData.lastName}
            </Typography>
            <Typography>
              {clientData.firstName}
            </Typography>
            <Typography>
              {clientData.phone}
            </Typography>
            <Typography>
              {clientData.email}
            </Typography>
          </div>
        </div>

        <div>
          <Typography variant="h5" className="mb-4">
            Signature
          </Typography>
          <div className="border border-gray-300 rounded-lg">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{
                className: "w-full h-[400px]",
                style: { 
                  borderRadius: "0.5rem",
                  backgroundColor: "#fff"
                }
              }}
            />
          </div>
          <div className="flex justify-end mt-2">
            <Button
              variant="text"
              color="gray"
              size="sm"
              onClick={() => signatureRef.current?.clear()}
            >
              Effacer
            </Button>
          </div>
        </div>

        {error && (
          <Typography color="red" className="text-center">
            {error}
          </Typography>
        )}

        <Button
          onClick={handleSubmit}
          disabled={loading}
          className="w-full"
          style={{ backgroundColor: colors.dimoDark3 }}
        >
          {loading ? (
            <Spinner className="h-4 w-4 mx-auto" />
          ) : (
            <>Valider cette offre 🤗</>
          )}
        </Button>
      </div>
    </Card>
  );
};

export default SignQuote;